.container {
  width: 100dvw;
  height: 100dvh;
  background: oklch(74% 0.02 145);
}

.panels {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
}
