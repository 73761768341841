.container {
  display: flex;
  gap: 5px;
}

.left {
  flex-direction: row;
}

.right {
  flex-direction: row-reverse;
}
